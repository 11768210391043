import React, { useEffect } from "react";

import "./Customizer.css";

import { Portrait } from "../components/Portrait";
import { NavigationControls } from "../components/NavigationControls";
import { CategoryPicker } from "../components/CategoryPicker";
import { OptionPicker } from "../components/OptionPicker";
import { ColorPicker } from "../components/ColorPicker";
import { fetchPresetAssets } from "../helpers/avatarAssets";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { CultureName } from "../constants";
import { useCultureRedirect } from "../helpers/cultureRedirect";

const { POSTSALE } = process.env;

export const Customizer: React.FC = () => {
  const cultureRedirect = useCultureRedirect()

  useEffect(() => {
    cultureRedirect.navigate();

    // Warm the cache for all assets included in a preset so that the restart
    // button feels fast.
    fetchPresetAssets();
  }, []);

  return (
    <div id="avatar-customizer-container">
      <div id="avatar-customizer">
        <Portrait />
        <CategoryPicker />
        <OptionPicker />
        <NavigationControls />
        <ColorPicker />

        <Link to={POSTSALE ? "/view" : "/finalize"} id="avatar-customizer-finalize-button">
          <button className="shiny-button" tabIndex={-1}>
            Finalize Avatar
          </button>
        </Link>
      </div>
    </div>
  );
};
